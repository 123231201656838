export const traits = [
  {
    key: "Funny",
    value: "😂 Funny",
  },
  {
    key: "Cheeky",
    value: "😉 Cheeky",
  },
  {
    key: "Innocent",
    value: "😇 Innocent",
  },
  {
    key: "Loving",
    value: "🥰 Loving",
  },
  {
    key: "Crazy",
    value: "🤪 Crazy",
  },
  {
    key: "Suspicious",
    value: "🤨 Suspicious",
  },
  {
    key: "Detective",
    value: "🕵️‍♂️ Detective",
  },
  {
    key: "Angry",
    value: "😡 Angry",
  },
  {
    key: "Mischievous",
    value: "😈 Mischievous",
  },
  {
    key: "Gamer",
    value: "👾 Gamer",
  },
  {
    key: "Loves To Make Dua",
    value: "🤲 Loves To Make Dua",
  },
  {
    key: "Hafidh",
    value: "📖 Hafidh",
  },
  {
    key: "Strong",
    value: "💪 Strong",
  },
  {
    key: "Dentist",
    value: "🦷 Dentist",
  },
  {
    key: "Policeman",
    value: "👮‍♂️ Policeman",
  },
  {
    key: "Tired",
    value: "😴 Tired",
  },
  {
    key: "Saluting",
    value: "🫡 Saluting",
  },
  {
    key: "Cold",
    value: "🥶 Cold",
  },
  {
    key: "Cowboy",
    value: "🤠 Cowboy",
  },
  {
    key: "Passionate",
    value: "❤️‍🔥 Passionate",
  },
  {
    key: "Palm Down Hand",
    value: "🫳 Palm Down Hand",
  },
  {
    key: "Handshaker",
    value: "🤝 Handshaker",
  },
  {
    key: "Selfie Connoisseur",
    value: "🤳 Selfie Connoisseur",
  },
  {
    key: "Intelligent",
    value: "🧠 Intelligent",
  },
  {
    key: "Good with Kids",
    value: "👶 Good with Kids",
  },
  {
    key: "Beard",
    value: "🧔 Beard",
  },
  {
    key: "Good Teeth",
    value: "🦷 Good Teeth",
  },
  {
    key: "Bald",
    value: "🦲 Bald",
  },
  {
    key: "Deaf",
    value: "🧏‍♂️ Deaf",
  },
  {
    key: "Astronaut",
    value: "👨‍🚀 Astronaut",
  },
  {
    key: "Firefighter",
    value: "👨‍🚒 Firefighter",
  },
  {
    key: "Scientist",
    value: "👨‍🔬 Scientist",
  },
  {
    key: "Mechanic",
    value: "👨‍🔧 Mechanic",
  },
  {
    key: "Judge",
    value: "👨‍⚖️ Judge",
  },
  {
    key: "Chef",
    value: "👨‍🍳 Chef",
  },
  {
    key: "Artist",
    value: "👨‍🎨 Artist",
  },
  {
    key: "Pilot",
    value: "👨‍✈️ Pilot",
  },
  {
    key: "Farmer",
    value: "👨‍🌾 Farmer",
  },
  {
    key: "Graduate",
    value: "👨‍🎓 Graduate",
  },
  {
    key: "Teacher",
    value: "👨‍🏫 Teacher",
  },
  {
    key: "Programmer",
    value: "👨‍💻 Programmer",
  },
  {
    key: "Businessman",
    value: "👨‍💼 Businessman",
  },
  {
    key: "Nurse",
    value: "👨‍⚕️ Nurse",
  },
  {
    key: "Weightlifter",
    value: "🏋️‍♂️ Weightlifter",
  },
  {
    key: "Horse Rider",
    value: "🏇 Horse Rider",
  },
  {
    key: "Surfer",
    value: "🏄‍♂️ Surfer",
  },
  {
    key: "Snowboarder",
    value: "🏂 Snowboarder",
  },
  {
    key: "Ninja",
    value: "🥷 Ninja",
  },
  {
    key: "King",
    value: "🤴 King",
  },
  {
    key: "Construction Worker",
    value: "👷‍♂️ Construction Worker",
  },
  {
    key: "Blind",
    value: "👨‍🦯 Blind",
  },
  {
    key: "Wheelchair User",
    value: "🧑‍🦽 Wheelchair User",
  },
  {
    key: "Panda",
    value: "🐼 Panda",
  },
  {
    key: "Powerful",
    value: "🐅 Powerful",
  },
  {
    key: "Brave",
    value: "🦁 Brave",
  },
  {
    key: "Wise",
    value: "🦉 Wise",
  },
  {
    key: "Majestic",
    value: "🦅 Majestic",
  },
  {
    key: "Delicate",
    value: "🦋 Delicate",
  },
  {
    key: "Blooming",
    value: "🌷 Blooming",
  },
  {
    key: "Romantic",
    value: "🌹 Romantic",
  },
  {
    key: "Exotic",
    value: "🌺 Exotic",
  },
  {
    key: "Cheerful",
    value: "🌼 Cheerful",
  },
  {
    key: "Talkative",
    value: "🦜 Talkative",
  },
  {
    key: "Graceful",
    value: "🦢 Graceful",
  },
  {
    key: "Flamboyant",
    value: "🦩 Flamboyant",
  },
  {
    key: "Tall",
    value: "🦒 Tall",
  },
  {
    key: "Dangerous",
    value: "🦂 Dangerous",
  },
  {
    key: "Sunny",
    value: "🌻 Sunny",
  },
  {
    key: "Wavy",
    value: "🌊 Wavy",
  },
  {
    key: "Winner",
    value: "🏅 Winner",
  },
  {
    key: "Good Talker",
    value: "🎙️ Good Talker",
  },
  {
    key: "Single Father",
    value: "👨‍👦 Single Father",
  },
  {
    key: "Eco Friendly",
    value: "🚮 Eco Friendly",
  },
  {
    key: "Drinks Tapwater",
    value: "🚰 Drinks Tapwater",
  },
  {
    key: "Walking Red Flag",
    value: "🚩 Walking Red Flag",
  },
  {
    key: "Has Children",
    value: "🚸 Has Children",
  },
  {
    key: "Prayer Level 1000",
    value: "🛐 Prayer Level 1000",
  },
];

export const deenTraits = [
  {
    key: "Masjid Goer",
    value: "🕌 Masjid Goer",
  },
  {
    key: "Tasbih User",
    value: "📿 Tasbih User",
  },
  {
    key: "Done Hajj",
    value: "🕋 Done Hajj",
  },
  {
    key: "Done Umrah",
    value: "🕋 Done Umrah",
  },
  {
    key: "Dua",
    value: "🤲 Dua",
  },
  {
    key: "Quran Reader",
    value: "📖 Quran Reader",
  },
  {
    key: "Prayer Devotee",
    value: "🛐 Prayer Devotee",
  },
  {
    key: "Ramadan Observer",
    value: "🌙 Ramadan Observer",
  },
  {
    key: "Sadaqah",
    value: "🍲 Sadaqah",
  },
  {
    key: "Islamic Lecturer",
    value: "🎤 Islamic Lecturer",
  },
  {
    key: "Traditional Dress",
    value: "👳 Traditional Dress",
  },
  {
    key: "Arabic Speaker",
    value: "💬 Arabic Speaker",
  },
  {
    key: "Islamic Calligrapher",
    value: "✍️ Islamic Calligrapher",
  },
  {
    key: "Family Values",
    value: "👪 Family Values",
  },
  {
    key: "Community Service",
    value: "🤝 Community Service",
  },
  {
    key: "Spiritual Learner",
    value: "💡 Spiritual Learner",
  },
  {
    key: "Peace Advocate",
    value: "🕊️ Peace Advocate",
  },
  {
    key: "Student Of Knowldege",
    value: "🎓 Student Of Knowldege",
  },
];

export const recreationTraits = [
  {
    key: "Board Games",
    value: "🎲 Board Games",
  },
  {
    key: "Playing Card Games",
    value: "🃏 Playing Card Games",
  },
  {
    key: "Fashion",
    value: "👗 Fashion",
  },
  {
    key: "Museums",
    value: "🏛️ Museums",
  },
  {
    key: "Reading",
    value: "📚 Reading",
  },
  {
    key: "Tropics",
    value: "🌴 Tropics",
  },
  {
    key: "Nature Lover",
    value: "🪴 Nature Lover",
  },
  {
    key: "Flower Lover",
    value: "💐 Flower Lover",
  },
  {
    key: "Cat Lover",
    value: "🐱 Cat Lover",
  },
  {
    key: "Mountain Lover",
    value: "🏔️ Mountain Lover",
  },
  {
    key: "Sunrise Lover",
    value: "🌅 Sunrise Lover",
  },
  {
    key: "Sunset Lover",
    value: "🌄 Sunset Lover",
  },
  {
    key: "Star Lover",
    value: "🌌 Star Lover",
  },
];

export const foodDrinksTravelTraits = [
  {
    key: "Mango",
    value: "🥭 Mango",
  },
  {
    key: "Avocado",
    value: "🥑 Avocado",
  },
  {
    key: "Potato",
    value: "🥔 Potato",
  },
  {
    key: "Spice",
    value: "🌶️ Spice",
  },
  {
    key: "Steak",
    value: "🥩 Steak",
  },
  {
    key: "Ramen",
    value: "🍜 Ramen",
  },
  {
    key: "Sushi",
    value: "🍣 Sushi",
  },
  {
    key: "Ice Cream",
    value: "🍦 Ice Cream",
  },
  {
    key: "Chocolate",
    value: "🍫 Chocolate",
  },
  {
    key: "Coffee",
    value: "☕ Coffee",
  },
  {
    key: "Tea",
    value: "🫖 Tea",
  },
  {
    key: "Bubble Tea",
    value: "🧋 Bubble Tea",
  },
  {
    key: "Matcha",
    value: "🍵 Matcha",
  },
  {
    key: "Beaches",
    value: "🏝️ Beaches",
  },
  {
    key: "Architecture",
    value: "🏛️ Architecture",
  },
  {
    key: "Umrah",
    value: "🕋 Umrah",
  },
  {
    key: "Camping",
    value: "⛺ Camping",
  },
  {
    key: "Fun Fairs",
    value: "🎡 Fun Fairs",
  },
  {
    key: "Theme Parks",
    value: "🎢 Theme Parks",
  },
  {
    key: "Bullet Train",
    value: "🚄 Bullet Train",
  },
  {
    key: "Cruises",
    value: "🚢 Cruises",
  },
  {
    key: "Jetsetter",
    value: "🛩️ Jetsetter",
  },
  {
    key: "Skydiving",
    value: "🪂 Skydiving",
  },
];

export const sportsMiscTraits = [
  {
    key: "Fireworks",
    value: "🎆 Fireworks",
  },
  {
    key: "Competitions",
    value: "🏆 Competitions",
  },
  {
    key: "Football",
    value: "⚽ Football",
  },
  {
    key: "Basketball",
    value: "🏀 Basketball",
  },
  {
    key: "Rugby",
    value: "🏉 Rugby",
  },
  {
    key: "American Football",
    value: "🏈 American Football",
  },
  {
    key: "Baseball",
    value: "⚾ Baseball",
  },
  {
    key: "Bowling",
    value: "🎳 Bowling",
  },
  {
    key: "Cricket",
    value: "🏏 Cricket",
  },
  {
    key: "Ice Hockey",
    value: "🏒 Ice Hockey",
  },
  {
    key: "Ping Pong",
    value: "🏓 Ping Pong",
  },
  {
    key: "Badminton",
    value: "🏸 Badminton",
  },
  {
    key: "Boxing",
    value: "🥊 Boxing",
  },
  {
    key: "Jiu Jitsu",
    value: "🥋 Jiu Jitsu",
  },
  {
    key: "Golf",
    value: "🏌️ Golf",
  },
  {
    key: "Ice Skating",
    value: "⛸️ Ice Skating",
  },
  {
    key: "Gaming",
    value: "🎮 Gaming",
  },
  {
    key: "Painting",
    value: "🎨 Painting",
  },
  {
    key: "Sewing",
    value: "🪡 Sewing",
  },
  {
    key: "Shopping",
    value: "🛍️ Shopping",
  },
];

export const matches = [
  {
    key: "reverts",
    value: "🤲 Reverts",
  },
  {
    key: "widows",
    value: "🧕🏾 Widows/Widowers",
  },
  {
    key: "divorcees",
    value: "⚮ Divorcees",
  },
  {
    key: "parents",
    value: "👨‍👦 Parents",
  },
];
