import { Paper, Typography, Divider, Chip, Box, Stack } from "@mui/material";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { capitalizeFirstLetter } from "../../helpers";
import { Typography as AntTypo } from "antd";

const UserCard = ({ user, isSearch = false }) => {
  const navigate = useNavigate();

  const string = user.summary || "";

  const maxLength = 200;

  return (
    <Paper
      elevation={1}
      sx={{
        padding: "10px",
        width: "100%",
        cursor: "pointer",
      }}
      onClick={() => navigate(`/profile/${user.username}`)}
    >
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Box>
          <Typography
            component="strong"
            sx={{
              textTransform: "capitalize",
              fontWeight: "bold",
              color: "#545e6f",
            }}
          >
            {user.username} {user.kunya && `(${user.kunya})`}
          </Typography>
        </Box>
        <Box>
          {user.isMatched ? (
            <Chip label="Matched" color="success" size="small" />
          ) : user.isSent ? (
            <Chip label="Request sent" color="success" size="small" />
          ) : (
            user.isReceived && (
              <Chip label="Request received" color="success" size="small" />
            )
          )}

          {!user.isMatched && (
            <>
              {user.hasBeenRejectedByMe && (
                <Chip
                  label="You rejected this request"
                  color="error"
                  size="small"
                />
              )}
            </>
          )}

          {!user.isMatched && user.hasRejectedMe && (
            <Chip label="Rejected your request" color="error" size="small" />
          )}
        </Box>
      </Stack>
      <Divider variant="middle" sx={{ marginY: "5px" }} />

      {string ? (
        <Typography
          sx={{
            color: "#545e6f",
          }}
        >
          {string.length > maxLength
            ? `${string.substring(0, maxLength)}...`
            : string}
        </Typography>
      ) : (
        <AntTypo.Text type="secondary">User summary not set</AntTypo.Text>
      )}
      <div>
        <Typography component={"i"}>
          <AntTypo.Text type="secondary">
            {isSearch && "Last seen: "}

            {capitalizeFirstLetter(
              moment(
                isSearch
                  ? user?.lastSeen || user.created
                  : user.latest_follow_date
              ).fromNow()
            )}
          </AntTypo.Text>
        </Typography>
      </div>
    </Paper>
  );
};

export default UserCard;
